import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { PpConfigSchema } from "./ConfigSchema/PpConfigSchema";
import { StripeBalanceImport } from "./Reports/StripeBalanceImport";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,
    ...CustomViewComponentsMap,

    'reports.stripe-dispute.upload-form': StripeBalanceImport,

    config: {
        paypal: PpConfigSchema,
        crm: CrmConfigSchema,
    }
}