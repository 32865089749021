
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorDisputeNae, SelectorSystemRunNae, SelectorWebhookNae } from '../models/ormSelectors';
import { useDisputeHookNae } from '../hooks/useDisputeHookNae';
import { useSystemRunHookNae } from '../hooks/useSystemRunHookNae';
import { useWebhookHookNae } from '../hooks/useWebhookHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'dispute') {
    return useDisputeHookNae;
  }
  if (schema === 'system-run') {
    return useSystemRunHookNae;
  }
  if (schema === 'webhook') {
    return useWebhookHookNae;
  }
  return selector;
}

export function DisputeEmptyFieldNae(props: PropsLink) {
  const element = useDisputeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SystemRunEmptyFieldNae(props: PropsLink) {
  const element = useSystemRunHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function WebhookEmptyFieldNae(props: PropsLink) {
  const element = useWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

