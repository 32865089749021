import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "chargeback-help-alert",
        "key": "alertResponses",
        "type": "array",
        "format": "chargeback-help-alert-response",
        "title": "alertResponses",
        "additionalProperties": [
            {
                "mapped": "chargeBackHelpAlert"
            }
        ],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "alertTimestamp",
        "type": "string",
        "format": "date-time",
        "title": "alertTimestamp",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "amount",
        "type": "string",
        "format": "",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "arn",
        "type": "string",
        "format": "",
        "title": "arn",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "authCode",
        "type": "string",
        "format": "",
        "title": "authCode",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "cardBin",
        "type": "string",
        "format": "",
        "title": "cardBin",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "cardBrand",
        "type": "string",
        "format": "",
        "title": "cardBrand",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "cardLast4",
        "type": "string",
        "format": "",
        "title": "cardLast4",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "chargeBackAt",
        "type": "string",
        "format": "date-time",
        "title": "chargeBackAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "consumerName",
        "type": "string",
        "format": "",
        "title": "consumerName",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "creditOutcome",
        "type": "string",
        "format": "",
        "title": "creditOutcome",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "creditReason",
        "type": "array",
        "format": "string",
        "title": "creditReason",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "creditRequestAt",
        "type": "string",
        "format": "date-time",
        "title": "creditRequestAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "creditedAt",
        "type": "string",
        "format": "date-time",
        "title": "creditedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "descriptorId",
        "type": "integer",
        "format": "",
        "title": "descriptorId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "descriptorName",
        "type": "string",
        "format": "",
        "title": "descriptorName",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "disputeAlertResponse",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "duplicateId",
        "type": "integer",
        "format": "",
        "title": "duplicateId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "externalId",
        "type": "string",
        "format": "",
        "title": "externalId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "externalLiability",
        "type": "string",
        "format": "",
        "title": "externalLiability",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "externalMerchantId",
        "type": "string",
        "format": "",
        "title": "externalMerchantId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "externalSource",
        "type": "string",
        "format": "",
        "title": "externalSource",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "gateway",
        "type": "string",
        "format": "",
        "title": "gateway",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "gatewayId",
        "type": "string",
        "format": "",
        "title": "gatewayId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "gatewayRefundId",
        "type": "string",
        "format": "",
        "title": "gatewayRefundId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "issuer",
        "type": "string",
        "format": "",
        "title": "issuer",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "mcc",
        "type": "integer",
        "format": "",
        "title": "mcc",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "merchantId",
        "type": "integer",
        "format": "",
        "title": "merchantId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "merchantName",
        "type": "string",
        "format": "",
        "title": "merchantName",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "message",
        "type": "string",
        "format": "",
        "title": "message",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "outcome",
        "type": "string",
        "format": "",
        "title": "outcome",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "overlapId",
        "type": "integer",
        "format": "",
        "title": "overlapId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "refundAlertResponse",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "refunded",
        "type": "string",
        "format": "",
        "title": "refunded",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "resolveResponse",
        "type": "array",
        "format": "string",
        "title": "resolveResponse",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "resolvedAt",
        "type": "string",
        "format": "date-time",
        "title": "resolvedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "resolvedBy",
        "type": "string",
        "format": "",
        "title": "resolvedBy",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "sourceId",
        "type": "integer",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "statusCode",
        "type": "string",
        "format": "",
        "title": "statusCode",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "successAlertResponse",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "transactionTimestamp",
        "type": "string",
        "format": "date-time",
        "title": "transactionTimestamp",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "transactionType",
        "type": "string",
        "format": "",
        "title": "transactionType",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "chargeBackHelpAlert",
        "type": "rel",
        "format": "chargeback-help-alert",
        "title": "chargeBackHelpAlert",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "hasDispute",
        "type": "boolean",
        "format": "",
        "title": "hasDispute",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "hasRefund",
        "type": "boolean",
        "format": "",
        "title": "hasRefund",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "needCrmSync",
        "type": "boolean",
        "format": "",
        "title": "needCrmSync",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "processorId",
        "type": "string",
        "format": "",
        "title": "processorId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "success",
        "type": "integer",
        "format": "",
        "title": "success",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "chargeback-help-alert-response",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ChargebackHelpAlertResponse",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "amount",
        "type": "string",
        "format": "",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "authCode",
        "type": "string",
        "format": "",
        "title": "authCode",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "cardBin",
        "type": "string",
        "format": "",
        "title": "cardBin",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "cardBrand",
        "type": "string",
        "format": "",
        "title": "cardBrand",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "cardLast4",
        "type": "string",
        "format": "",
        "title": "cardLast4",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "dispute",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "dispute",
        "key": "orchestrator",
        "type": "string",
        "format": "",
        "title": "orchestrator",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "rdrDefended",
        "type": "boolean",
        "format": "",
        "title": "rdrDefended",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "dispute",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "dispute",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "Dispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "front-order",
        "key": "ba",
        "type": "string",
        "format": "",
        "title": "ba",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "baCancel",
        "type": "boolean",
        "format": "",
        "title": "baCancel",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "front-order",
        "key": "baSuccess",
        "type": "boolean",
        "format": "",
        "title": "baSuccess",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "front-order",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order",
        "key": "orderData",
        "type": "array",
        "format": "string",
        "title": "orderData",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "token",
        "type": "string",
        "format": "",
        "title": "token",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order-log",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "path",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "request",
        "type": "array",
        "format": "string",
        "title": "request",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order-log",
        "key": "response",
        "type": "array",
        "format": "string",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-dispute",
        "key": "createTime",
        "type": "string",
        "format": "date-time",
        "title": "createTime",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "createdAtFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeAmount",
        "type": "number",
        "format": "float",
        "title": "disputeAmount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeAmountCurrency",
        "type": "string",
        "format": "",
        "title": "disputeAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeChannel",
        "type": "string",
        "format": "",
        "title": "disputeChannel",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeId",
        "type": "string",
        "format": "",
        "title": "disputeId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeLifeCycleStage",
        "type": "string",
        "format": "",
        "title": "disputeLifeCycleStage",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeState",
        "type": "string",
        "format": "",
        "title": "disputeState",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputedTransactions",
        "type": "array",
        "format": "string",
        "title": "disputedTransactions",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputedTransactionsIds",
        "type": "string",
        "format": "",
        "title": "Transaction IDs",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-dispute",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "updateTime",
        "type": "string",
        "format": "date-time",
        "title": "updateTime",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "bankRefenceId",
        "type": "string",
        "format": "",
        "title": "bankRefenceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "cartInfo",
        "type": "array",
        "format": "string",
        "title": "cartInfo",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "creditTerm",
        "type": "string",
        "format": "",
        "title": "creditTerm",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "customField",
        "type": "string",
        "format": "",
        "title": "customField",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "feeAmount",
        "type": "number",
        "format": "float",
        "title": "feeAmount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-transaction",
        "key": "feeAmountCurrency",
        "type": "string",
        "format": "",
        "title": "feeAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-transaction",
        "key": "instrumentSubType",
        "type": "string",
        "format": "",
        "title": "instrumentSubType",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "instrumentType",
        "type": "string",
        "format": "",
        "title": "instrumentType",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "invoiceId",
        "type": "string",
        "format": "",
        "title": "invoiceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "paypal-transaction",
        "key": "payerInfo",
        "type": "array",
        "format": "string",
        "title": "payerInfo",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "paymentMethodType",
        "type": "string",
        "format": "",
        "title": "paymentMethodType",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paymentTrackingId",
        "type": "string",
        "format": "",
        "title": "paymentTrackingId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalAccountId",
        "type": "string",
        "format": "",
        "title": "paypalAccountId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalReferenceId",
        "type": "string",
        "format": "",
        "title": "paypalReferenceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalReferenceIdType",
        "type": "string",
        "format": "",
        "title": "paypalReferenceIdType",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "protectionEligibility",
        "type": "string",
        "format": "",
        "title": "protectionEligibility",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "shippingInfo",
        "type": "array",
        "format": "string",
        "title": "shippingInfo",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionAmount",
        "type": "number",
        "format": "float",
        "title": "transactionAmount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionAmountCurrency",
        "type": "string",
        "format": "",
        "title": "transactionAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionEventCode",
        "type": "string",
        "format": "",
        "title": "transactionEventCode",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionId",
        "type": "string",
        "format": "",
        "title": "transactionId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionInitiationDate",
        "type": "string",
        "format": "date-time",
        "title": "transactionInitiationDate",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionNote",
        "type": "string",
        "format": "",
        "title": "transactionNote",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionStatus",
        "type": "string",
        "format": "",
        "title": "transactionStatus",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionSubject",
        "type": "string",
        "format": "",
        "title": "transactionSubject",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionUpdatedDate",
        "type": "string",
        "format": "date-time",
        "title": "transactionUpdatedDate",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "userCanceled",
        "type": "boolean",
        "format": "",
        "title": "userCanceled",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "paypal-webhook",
        "key": "eventTypes",
        "type": "string",
        "format": "text",
        "title": "Events",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "paypal-webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-webhook",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "ID",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-webhook",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-webhook",
        "key": "url",
        "type": "string",
        "format": "text",
        "title": "Url",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "rdr-alert",
        "key": "amount",
        "type": "string",
        "format": "",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "arn",
        "type": "string",
        "format": "",
        "title": "arn",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "authCode",
        "type": "string",
        "format": "",
        "title": "authCode",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "cardAcceptorId",
        "type": "string",
        "format": "",
        "title": "cardAcceptorId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "cardBin",
        "type": "string",
        "format": "",
        "title": "cardBin",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "cardLast4",
        "type": "string",
        "format": "",
        "title": "cardLast4",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "descriptor",
        "type": "string",
        "format": "",
        "title": "descriptor",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "externalId",
        "type": "string",
        "format": "",
        "title": "externalId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "externalMerchantName",
        "type": "string",
        "format": "",
        "title": "externalMerchantName",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "externalOrderId",
        "type": "string",
        "format": "",
        "title": "externalOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert",
        "key": "importedAt",
        "type": "string",
        "format": "date-time",
        "title": "importedAt",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "issuerCaseNumber",
        "type": "string",
        "format": "",
        "title": "issuerCaseNumber",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "mcc",
        "type": "integer",
        "format": "",
        "title": "mcc",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert",
        "key": "merchantId",
        "type": "integer",
        "format": "",
        "title": "merchantId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert",
        "key": "merchantName",
        "type": "string",
        "format": "",
        "title": "merchantName",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "rdr-alert",
        "key": "outcome",
        "type": "string",
        "format": "",
        "title": "outcome",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "rdrResponses",
        "type": "array",
        "format": "rdr-alert-response",
        "title": "rdrResponses",
        "additionalProperties": [
            {
                "mapped": "rdrAlert"
            }
        ],
        "description": "",
        "className": "RdrAlert",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "rdr-alert",
        "key": "reasonCode",
        "type": "string",
        "format": "",
        "title": "reasonCode",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "receivedAt",
        "type": "string",
        "format": "date-time",
        "title": "receivedAt",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "resolveResponse",
        "type": "array",
        "format": "string",
        "title": "resolveResponse",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "rdr-alert",
        "key": "ruleName",
        "type": "string",
        "format": "",
        "title": "ruleName",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "ruleType",
        "type": "string",
        "format": "",
        "title": "ruleType",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "sourceId",
        "type": "integer",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert",
        "key": "transactionTimestamp",
        "type": "string",
        "format": "date-time",
        "title": "transactionTimestamp",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert",
        "key": "verifiName",
        "type": "string",
        "format": "",
        "title": "verifiName",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlert",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "hasDispute",
        "type": "boolean",
        "format": "",
        "title": "hasDispute",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "rdr-alert-response",
        "key": "hasRefund",
        "type": "boolean",
        "format": "",
        "title": "hasRefund",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "rdr-alert-response",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert-response",
        "key": "needCrmSync",
        "type": "boolean",
        "format": "",
        "title": "needCrmSync",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "rdr-alert-response",
        "key": "processorId",
        "type": "string",
        "format": "",
        "title": "processorId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "rdrAlert",
        "type": "rel",
        "format": "rdr-alert",
        "title": "rdrAlert",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "rdr-alert-response",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "rdr-alert-response",
        "key": "success",
        "type": "integer",
        "format": "",
        "title": "success",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "rdr-alert-response",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "RdrAlertResponse",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "recurring",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "recurring",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "recurring",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piId",
        "type": "string",
        "format": "",
        "title": "piId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piStatus",
        "type": "string",
        "format": "",
        "title": "piStatus",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "refund-log",
        "key": "chargeBackHelpAlert",
        "type": "rel",
        "format": "chargeback-help-alert",
        "title": "chargeBackHelpAlert",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "refund-log",
        "key": "chargeBackHelpAlertResponse",
        "type": "rel",
        "format": "chargeback-help-alert-response",
        "title": "chargeBackHelpAlertResponse",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "refund-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "refund-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "refund-log",
        "key": "log",
        "type": "string",
        "format": "",
        "title": "log",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "refund-log",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "url",
        "additionalProperties": [],
        "description": "",
        "className": "RefundLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "refund-request",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "refund-request",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "refund-request",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "refund-request",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "refund-request",
        "key": "paypalTransaction",
        "type": "rel",
        "format": "paypal-transaction",
        "title": "paypalTransaction",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "refund-request",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "refund-request",
        "key": "transactionId",
        "type": "string",
        "format": "",
        "title": "transactionId",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "description",
        "type": "text",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "fee",
        "type": "number",
        "format": "float",
        "title": "fee",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-stripe-dispute",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "ReportStripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "resolve-log",
        "key": "chargeBackHelpAlert",
        "type": "rel",
        "format": "chargeback-help-alert",
        "title": "chargeBackHelpAlert",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "resolve-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "resolve-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "resolve-log",
        "key": "log",
        "type": "string",
        "format": "",
        "title": "log",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "resolve-log",
        "key": "refunded",
        "type": "string",
        "format": "",
        "title": "refunded",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "resolve-log",
        "key": "sent",
        "type": "boolean",
        "format": "",
        "title": "sent",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "resolve-log",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "resolve-log",
        "key": "statusCode",
        "type": "string",
        "format": "",
        "title": "statusCode",
        "additionalProperties": [],
        "description": "",
        "className": "ResolveLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "arguments",
        "type": "string",
        "format": "",
        "title": "Arguments",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "command",
        "type": "string",
        "format": "",
        "title": "Command",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeFinish",
        "type": "string",
        "format": "date-time",
        "title": "Execute Finish",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeStart",
        "type": "string",
        "format": "date-time",
        "title": "Execute Start",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "system-run",
        "key": "response",
        "type": "string",
        "format": "text",
        "title": "Response",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "system-run",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "webhook",
        "key": "data",
        "type": "array",
        "format": "string",
        "title": "data",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook",
        "key": "processed",
        "type": "boolean",
        "format": "",
        "title": "processed",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    }
]