import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorDisputeNae = createSelector(orm.DisputeModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorSystemRunNae = createSelector(orm.SystemRunModel);
export const SelectorWebhookNae = createSelector(orm.WebhookModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'Dispute') {
        return SelectorDisputeNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'SystemRun') {
        return SelectorSystemRunNae;
    }
        if (schema === 'Webhook') {
        return SelectorWebhookNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'dispute') {
        return SelectorDisputeNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'system-run') {
        return SelectorSystemRunNae;
    }
        if (schema === 'webhook') {
        return SelectorWebhookNae;
    }
    }