import { IDisputeFieldsNae } from './useDisputeHookNae';
import { ISystemRunFieldsNae } from './useSystemRunHookNae';
import { IWebhookFieldsNae } from './useWebhookHookNae';
import { SelectorDisputeNae,SelectorSystemRunNae,SelectorWebhookNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.Dispute.className) {
            return {
                dataToCheck: SelectorDisputeNae(store.getState()),
                fields: IDisputeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SystemRun.className) {
            return {
                dataToCheck: SelectorSystemRunNae(store.getState()),
                fields: ISystemRunFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Webhook.className) {
            return {
                dataToCheck: SelectorWebhookNae(store.getState()),
                fields: IWebhookFieldsNae,    
            }
        }
        return undefined;
}