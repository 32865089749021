import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "ChargebackHelpAlert",
        "schema": "chargeback-help-alert",
        "title": "Alert",
        "titlePlural": "Alerts",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChargebackHelpAlertResponse",
        "schema": "chargeback-help-alert-response",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Dispute",
        "schema": "dispute",
        "title": "Dispute",
        "titlePlural": "Disputes",
        "required": [],
        "scopes": []
    },
    {
        "className": "RdrAlert",
        "schema": "rdr-alert",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "RdrAlertResponse",
        "schema": "rdr-alert-response",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "RefundLog",
        "schema": "refund-log",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportStripeDispute",
        "schema": "report-stripe-dispute",
        "title": "Stripe disputes report",
        "titlePlural": "Stripe disputes report",
        "required": [],
        "scopes": []
    },
    {
        "className": "ResolveLog",
        "schema": "resolve-log",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SystemRun",
        "schema": "system-run",
        "title": "System run",
        "titlePlural": "System runs",
        "required": [
            "command"
        ],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Webhook",
        "schema": "webhook",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "SystemRun": {
        "className": "SystemRun",
        "schema": "system-run"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "ChargebackHelpAlert": {
        "className": "ChargebackHelpAlert",
        "schema": "chargeback-help-alert"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "RdrAlertResponse": {
        "className": "RdrAlertResponse",
        "schema": "rdr-alert-response"
    },
    "Dispute": {
        "className": "Dispute",
        "schema": "dispute"
    },
    "ChargebackHelpAlertResponse": {
        "className": "ChargebackHelpAlertResponse",
        "schema": "chargeback-help-alert-response"
    },
    "RdrAlert": {
        "className": "RdrAlert",
        "schema": "rdr-alert"
    },
    "Webhook": {
        "className": "Webhook",
        "schema": "webhook"
    },
    "ResolveLog": {
        "className": "ResolveLog",
        "schema": "resolve-log"
    },
    "RefundLog": {
        "className": "RefundLog",
        "schema": "refund-log"
    },
    "ReportStripeDispute": {
        "className": "ReportStripeDispute",
        "schema": "report-stripe-dispute"
    }
}