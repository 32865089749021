import { Card, Form, Window } from '@newageerp/crm-ui'
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import { Fragment, useState } from 'react'
import axios from "axios";
import { toast } from '@newageerp/v3.templates.templates-core';
import { useListDataSource } from "@newageerp/v3.app.list.list-data-source";

export const StripeBalanceImport = () => {
    const [showForm, setShowForm] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const listDataSource = useListDataSource()

    const uploadData = async () => {
        if (!!uploadedFile) {
            setUploading(true);

            const fData = new FormData();
            fData.append("file", uploadedFile);

            try {
            await axios
                .post("/app/plugins/reports/stripe-balance/upload", fData, {
                    headers: {
                        // @ts-ignore
                        'Authorization': window.localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                })
                setShowForm(false);
                listDataSource.data.reload.do();
                toast.success("Uploaded");
            } catch (e) {
                toast.error('Something went wrong...')
            }
            setUploading(false)
        }
    }

    return <Fragment>
        <ToolbarButton iconName='upload' onClick={() => setShowForm(true)} />
        <Window.Popup isPopup={showForm} onClick={() => setShowForm(false)}>
            <Card.WhiteCard>
                <div>
                    <Form.FileInput onChange={(e: any) => setUploadedFile(e.target.files[0])} />
                </div>
                <div>
                    <MainButton onClick={uploadData} iconName='upload' loading={uploading}>Import</MainButton>
                </div>
            </Card.WhiteCard>
        </Window.Popup>
    </Fragment>
}