import { ORM } from "redux-orm";
import DisputeModel from "./DisputeModel"
import QueueModel from "./QueueModel"
import SystemRunModel from "./SystemRunModel"
import WebhookModel from "./WebhookModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    DisputeModel,
    QueueModel,
    SystemRunModel,
    WebhookModel,
);

export default orm;